<template>
  <div
    v-if="!preloader && data.length || preloader"
    class="store"
  >
    <h2>
      {{ $t('app.page.store') }}
    </h2>

    <div class="store__grid">
      <template v-if="!preloader">
        <Card
          v-for="item in data"
          :key="item.id"
          :value="item"
        />
      </template>

      <template v-else>
        <div
          v-for="item in length"
          :key="item"
          class="store__grid__preloader-card"
        >
          <Preloader
            v-model:visible="preloader"
          />
        </div>
      </template>
    </div>

    <div class="store__buttons">
      <BlueButton
        tag="RouterLink"
        :to="{ name: 'StoreContainers' }"
        :text="$t('components.ui.pages.store.list.go-to-store')"
        style="max-width: 600px;"
      />
    </div>
  </div>
</template>

<script>
import BlueButton from '@/ui/buttons/BlueButton.vue'
import Card from '@/modules/store/ContainerCard.vue'
import Preloader from '@/ui/preloader/SkeletonAnimatePreloader.vue'

export default {
  components: {
    BlueButton,
    Card,
    Preloader
  },
  data () {
    return {
      mountFetch: false,
      preloader: true,
      length: 2,
      data: []
    }
  },
  activated () {
    if (this.mountFetch) {
      this.fetch()
    }
  },
  mounted () {
    this.fetch()
  },
  methods: {
    async fetch () {
      try {
        const { status, data } = await this.$axios.get(
          `private/containers/main?not-filled=true&limit=${this.length}&offset=0`)

        if (status === 200) {
          this.data = data.data
        }
      } catch (err) {
        throw new Error(err)
      } finally {
        this.preloader = false
        this.mountFetch = true
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.store {
  margin-top: 40px;
  width: 100%;

  h2 {
    margin-bottom: 20px;
    font-size: 32px;
    color: rgb(var(--color-dark));
    font-family: SemiBold;
  }

  &__grid {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;

    @media screen and (max-width: 1700px) {
      display: flex;
      flex-direction: column;
    }

    @media screen and (max-width: 768px) {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
    }

    @media screen and (max-width: 670px) {
      grid-template-columns: repeat(1, 1fr);
    }

    &__preloader-card {
      position: relative;
      height: 420px;
      background: #FFFFFF;
      box-shadow: 0px 5px 30px rgba(0, 0, 0, 0.06);
      border-radius: 15px;
      overflow: hidden;
    }
  }

  &__buttons {
    margin-top: 40px;
    display: flex;
    justify-content: center;

    a {
      @media screen and (max-width: 768px) {
        max-width: none !important;
      }
    }
  }
}
</style>
