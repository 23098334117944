<template>
  <Container
    class="chart"
    style="overflow: hidden !important;"
    :preloader="preloader"
  >
    <template #content>
      <Stats
        :value="value"
        is-main-page
      />
    </template>
  </Container>
</template>

<script>
import Container from '@/ui/preloader/ItemPreloaderContainer.vue'
import Stats from '@/modules/network-and-rank/rank/chart/Stats.vue'

export default {
  components: {
    Container,
    Stats
  },
  data () {
    return {
      preloader: true,
      value: {}
    }
  },
  mounted () {
    this.fetch()
  },
  methods: {
    async fetch () {
      try {
        const { status, data } = await this.$axios.get('private/widgets/network-rank')

        if (status === 200) {
          this.value = data.data
        }
      } catch (err) {
        throw new Error(err)
      } finally {
        this.preloader = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.chart {
  padding: 10px;
}
</style>
