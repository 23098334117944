<template>
  <Container
    class="chart"
    :preloader="preloader"
  >
    <template #content>
      <div class="chart__start">
        <p class="label">
          {{ $t('components.ui.pages.charts.income.income') }}
        </p>

        <h3>
          $ {{ $format.wallet(income.toFixed(2)) }}
        </h3>

        <p class="little-text">
          {{ $t('components.ui.pages.charts.income.per-month') }}
        </p>
      </div>

      <ArrowButton
        class="chart__button"
        :text="$t('components.ui.pages.charts.income.details')"
        @click="$router.push({ name: 'Balance', query: { tab: 'income' } })"
      />

      <LineChart
        v-if="!preloader"
        class="chart__graph"
        :chart-data="datacollection"
        :options="options"
        :plugins="plugins"
      />
    </template>
  </Container>
</template>

<script>
import { Chart, registerables } from 'chart.js'

import Container from '@/ui/preloader/ItemPreloaderContainer.vue'
import { LineChart } from 'vue-chart-3'
import ArrowButton from '@/ui/buttons/ArrowButton.vue'

Chart.register(...registerables)

export default {
  components: {
    Container,
    ArrowButton,
    LineChart
  },
  data () {
    return {
      preloader: true,
      mountFetch: false,
      income: 0,
      datacollection: {
        labels: [],
        datasets: [
          {
            data: [],
            borderColor: 'rgba(15, 191, 230, 0.5)',
            borderWidth: 0.5,
            fill: true,
            backgroundColor: (ctx) => {
              const canvas = ctx.chart.ctx
              const gradient = canvas.createLinearGradient(0, 0, 0, 75)

              gradient.addColorStop(0, 'rgba(15, 191, 230, 0.3)')
              gradient.addColorStop(1, 'rgba(15, 191, 230, 0)')

              return gradient
            },
            borderJoinStyle: 'round',
            tension: 0
          }
        ]
      },
      options: {
        interaction: {
          intersect: false,
          mode: 'nearest',
          axis: 'x'
        },
        plugins: {
          responsive: true,
          legend: {
            display: false
          },
          title: {
            display: false
          },
          tooltip: {
            enabled: false
          }
        },
        elements: {
          point: {
            pointBorderColor: 'transparent',
            pointBackgroundColor: 'transparent'
          }
        },
        layout: {
          padding: 0
        },
        scales: {
          y: {
            display: false,
            beginAtZero: true
          },
          x: {
            display: false,
            afterFit: scale => {
              scale.paddingLeft -= 10
              scale.paddingRight -= 10
            }
          }
        }
      }
    }
  },
  activated () {
    if (this.mountFetch) {
      this.fetch()
    }
  },
  mounted () {
    this.fetch()
  },
  methods: {
    async fetch () {
      try {
        const resp = await this.$axios.get(
          '/private/widgets/income-month')

        if (resp.status === 200) {
          this.income = resp.data.data.income
          this._parseData(resp.data.data.graph)
        }
      } catch (err) {
        throw new Error(err)
      } finally {
        this.preloader = false
        this.mountFetch = true
      }
    },
    _parseData (value) {
      var labels = []
      var data = []

      for (const i in value) {
        labels.push(i)
        data.push(value[i])
      }

      if (value.length === 0) {
        labels.push(0, 1)
        data.push(500, 500)
      }

      this.datacollection.labels = labels
      this.datacollection.datasets[0].data = data
    }
  }
}
</script>

<style lang="scss" scoped>
.chart {
  position: relative;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;

  @media screen and (max-width: 768px) {
    padding: 15px;
  }

  .chart__start {
    position: relative;
    z-index: 2;

    p {
      &.label {
        color: rgb(var(--color-dark));
        font-size: 16px;
        font-family: Medium;
      }

      &.little-text {
        margin-top: 3px;
        color: rgba(var(--text-color-light-gray));
      }
    }

    h3 {
      color: rgb(var(--color-dark));
      line-height: 100%;
      font-family: SemiBold;

      @media screen and (max-width: 768px) {
        font-size: 20px;
      }
    }
  }

  .chart__graph {
    position: absolute !important;
    bottom: 55px;
    left: 0;
    height: 80px;
    width: 100%;
    transform: scale(1.1);
    z-index: 1;
  }

  .chart__button {
    position: relative;
    z-index: 2;

    @media screen and (max-width: 768px) {
      margin-top: 65px;
    }
  }
}
</style>
