<template>
  <UiPage
    :title="$t('app.page.main')"
    :titleTemplate="$t('app.page.main')"
    :is-top="false"
    class="null"
  >
    <template
      v-if="!$store.state.window.to768"
      #content
    >
      <div class="dashboard">
        <ChartGrid />

        <InviteBunner />
      </div>

      <Banners />

      <ListContainerCard />

      <ListSecretBox />
    </template>

    <template
      v-else
      #content
    >
      <MobileMainChartBalanceHistory />

      <MobileMainGridCharts />

      <div class="mobile-padding">
        <InviteBunner />

        <Banners />

        <ListContainerCard />

        <Promoution
          style="margin-top: 30px;"
        />

        <ListSecretBox />
      </div>
    </template>
  </UiPage>
</template>

<script>
import UiPage from '@/ui/Page.vue'
import ChartGrid from '@/modules/main/ChartGrid.vue'
import InviteBunner from '@/modules/InviteBanner.vue'
import ListContainerCard from '@/modules/store/MainListContainerCard.vue'
import MobileMainChartBalanceHistory from '@/modules/main/MobileChartBalancehistory.vue'
import MobileMainGridCharts from '@/modules/main/MobileGridCharts.vue'
import Promoution from '@/modules/main/Promoution.vue'
import Banners from '@/modules/Banners.vue'
import ListSecretBox from '@/modules/store/secret-box/MainList.vue'

export default {
  components: {
    UiPage,
    ChartGrid,
    InviteBunner,
    ListContainerCard,
    MobileMainChartBalanceHistory,
    MobileMainGridCharts,
    Promoution,
    Banners,
    ListSecretBox
  }
}
</script>

<style lang="scss" scoped>
.dashboard {
  width: 100%;
  display: flex;
}

@media screen and (max-width: 1720px) {
  .dashboard {
    display: grid;
    grid-template-columns: 1fr;
    gap: 20px;
  }
}
</style>
