<template>
  <div
    class="balance"
    :class="{
      isPreloader : preloader
    }"
  >
    <SkeletonAnimate
      class="black"
      :visible="preloader"
      style="z-index: 5 !important;"
    />

    <div class="balance__top">
      <div class="balance__top__nav">
        <UserId />
      </div>

      <div class="balance__top__network">
        <div class="balance__top__network__item">
          <div class="balance__top__network__item__name">
            {{ $t('components.ui.pages.charts.balance-history.network-wide-procurement') }}
          </div>

          <h3>
            ${{ $format.wallet(data.turnover) }}
          </h3>

          <div class="balance__top__network__item__before">
            {{ $t('components.ui.pages.charts.balance-history.with-last-month') }}

            <br>

            <span>
              {{ data.delta_turnover }}%
            </span>
          </div>
        </div>

        <div class="balance__top__network__item">
          <div class="balance__top__network__item__name green-color">
            {{ $t('components.ui.pages.charts.balance-history.network-wide-turnover') }}
          </div>

          <h3>
            ${{ $format.wallet(data.turnover_products) }}
          </h3>

          <div class="balance__top__network__item__before green-color">
            {{ $t('components.ui.pages.charts.balance-history.with-last-month') }}

            <br>

            <span>
              {{ data.delta_turnover_products }}%
            </span>
          </div>
        </div>
      </div>
    </div>

    <div class="balance__chart">
      <LineChart
        v-if="!preloader"
        class="balance__chart__canvas"
        :chart-data="datacollection"
        :options="options"
        :plugins="plugins"
      />
    </div>

    <div class="balance__button">
      <LigthButton
        class="h46 blue"
        :text="$t('components.ui.pages.mobile.chart-balance.containers')"
        @click="$router.push({ name: 'StoreContainers' })"
      />

      <LigthButton
        class="h46"
        style="margin-top: 15px;"
        :text="$t('components.ui.pages.mobile.chart-balance.withdraval')"
        @click="$pop.up('withdrawal')"
      />
    </div>
  </div>
</template>

<script>
import { Chart, registerables } from 'chart.js'
import { LineChart } from 'vue-chart-3'
import vClickOutside from 'click-outside-vue3'

import LigthButton from '@/ui/buttons/LightButton.vue'
import SkeletonAnimate from '@/ui/preloader/SkeletonAnimatePreloader.vue'
import UserId from '@/ui/buttons/UserId.vue'

Chart.register(...registerables)

export default {
  directives: {
    clickOutside: vClickOutside.directive
  },
  components: {
    LineChart,
    LigthButton,
    UserId,
    SkeletonAnimate
  },
  data () {
    return {
      mountFetch: false,
      preloader: false,
      isDatePick: false,
      data: {},
      date: null,

      datacollection: {
        labels: [],
        datasets: [
          {
            data: [],
            borderColor: 'rgba(15, 191, 230, 1)',
            borderWidth: 2,
            fill: true,
            backgroundColor: (ctx) => {
              const canvas = ctx.chart.ctx
              const gradient = canvas.createLinearGradient(0, 0, 0, 65)

              gradient.addColorStop(0, 'rgba(15, 191, 230, 0.3)')
              gradient.addColorStop(1, 'rgba(15, 191, 230, 0)')

              return gradient
            },
            borderJoinStyle: 'round',
            tension: 0
          },
          {
            data: [],
            borderColor: 'rgba(0, 178, 7, 1)',
            borderWidth: 2,
            fill: true,
            backgroundColor: (ctx) => {
              const canvas = ctx.chart.ctx
              const gradient = canvas.createLinearGradient(0, 0, 0, 65)

              gradient.addColorStop(0, 'rgba(0, 178, 7, 0.3)')
              gradient.addColorStop(1, 'rgba(0, 178, 7, 0)')

              return gradient
            },
            borderJoinStyle: 'round',
            tension: 0
          }
        ]
      },
      options: {
        interaction: {
          intersect: false,
          mode: 'nearest',
          axis: 'x'
        },
        plugins: {
          responsive: true,
          legend: {
            display: false
          },
          title: {
            display: false
          },
          tooltip: {
            callbacks: {
              title: function () {},
              label (tooltipItem, data) {
                const val = new Intl.NumberFormat('en-US').format(tooltipItem.raw)
                return `$ ${val}`
              }
            },
            backgroundColor: '#202539',
            displayColors: false,
            caretPadding: 1,
            bodyColor: '#FFFFFF',
            bodyFont: {
              size: 14,
              family: 'Medium'
            }
          }
        },
        elements: {
          point: {
            pointHitRadius: 14,
            pointBorderColor: 'transparent',
            pointBackgroundColor: 'transparent',
            pointHoverBorderColor: 'rgba(15, 191, 230, 1)',
            pointHoverBackgroundColor: '#0E0E1B',
            hoverBorderWidth: 3
          },
          line: {
            borderCapStyle: 'round'
          }
        },
        scales: {
          y: {
            display: false,
            beginAtZero: true
          },
          x: {
            position: 'right',
            min: 0,
            ticks: {
              padding: 10,
              color: 'rgba(120, 124, 141, 1)',
              font: {
                size: 14,
                family: 'Medium'
              }
            },
            grid: {
              display: false,
              drawBorder: false
            }
          }
        }
      },
      plugins: [{
        beforeDraw: chart => {
          if (chart.tooltip?._active?.length) {
            const x = chart.tooltip._active[0].element.x
            const yAxis = chart.scales.y
            const ctx = chart.ctx

            ctx.save()
            ctx.beginPath()
            ctx.setLineDash([4, 8])
            ctx.lineWidth = 1
            ctx.strokeStyle = '#787C8D'
            ctx.moveTo(x, yAxis.top)
            ctx.lineTo(x, yAxis.bottom)
            ctx.stroke()
            ctx.restore()
          }
        }
      }]
    }
  },
  activated () {
    if (this.mountFetch) {
      this.fetch(false)
    }
  },
  mounted () {
    this.fetch()
  },
  computed: {
    isMonthlyGraph () {
      const monthly = this.data?.graph?.monthly
      var value

      if (monthly) {
        for (const i in monthly) {
          value.push(monthly[i])
        }
      }

      return value?.lenght >= 2
    }
  },
  methods: {
    async fetch (isCacheFetch = true) {
      try {
        if (isCacheFetch) {
          this.preloader = true
        }

        const resp = await this.$axios.get(
          '/private/widgets/turnover')

        if (resp.status === 200) {
          this.data = resp.data.data
          this.setGraphData(false)
        }
      } catch (err) {
        throw new Error(err)
      } finally {
        setTimeout(() => {
          this.preloader = false
        }, 200)

        this.mountFetch = true
      }
    },
    setGraphData (isCacheFetch = true) {
      if (isCacheFetch) {
        this.preloader = true
      }

      const graph = this.data.graph
      const turnover = graph.turnover
      const turnoverProducts = graph.turnover_products

      const labels = []

      const turnoverDate = []
      const turnoverProductsDate = []

      for (const i in turnover) {
        turnoverDate.push(turnover[i] || 0)
        turnoverProductsDate.push(turnoverProducts[i] || 0)
      }

      for (const i in turnover) {
        labels.push(
          new Intl.DateTimeFormat(this.$i18n.locale, { day: '2-digit' }).format(new Date(i)))
      }

      const labelsSlice = labels?.slice(-8)
      const turnoverDateSlice = turnoverDate?.slice(-8)
      const turnoverProductsDateSlice = turnoverProductsDate?.slice(-8)

      labelsSlice.unshift('')
      labelsSlice.push('')

      turnoverDateSlice.unshift(turnoverDateSlice[0])
      turnoverDateSlice.push(turnoverDateSlice[turnoverDateSlice.length - 1])
      turnoverProductsDateSlice.unshift(turnoverProductsDateSlice[0])
      turnoverProductsDateSlice.push(turnoverProductsDateSlice[turnoverProductsDateSlice.length - 1])

      this.datacollection.labels = labelsSlice
      this.datacollection.datasets[0].data = turnoverDateSlice
      this.datacollection.datasets[1].data = turnoverProductsDateSlice

      setTimeout(() => {
        this.preloader = false
      }, 500)
    },
    hideDatePick () {
      setTimeout(() => {
        this.isDatePick = false
      }, 250)
    }
  }
}
</script>

<style lang="scss" scoped>
.balance {
  position: relative;
  width: 100%;
  padding: 20px 0 0;
  background: #0E0E1B;

  &.isPreloader {
    overflow: hidden;
  }

  &__top {
    padding: 0 17px;

    &__nav {
      margin-bottom: 20px;
      padding-bottom: 15px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid #9CA3B8;
    }

    &__network {
      margin-bottom: 20px;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 20px;

      &__item {
        &__name {
          font-size: 12px;
          line-height: 100%;
          color: #2F57E9;

          &.green-color {
            color: rgba(0, 173, 48, 1);
          }
        }

        h3 {
          margin: 15px 0 10px;
          font-size: 24px;
          line-height: 100%;
          color: #FFFFFF;
          font-family: SemiBold;
        }

        &__before {
          font-size: 12px;
          line-height: 100%;
          color: #787C8D;
          font-family: Medium;

          span {
            margin-top: 2px;
            display: block;
            font-size: 14px;
            line-height: 100%;
            color: #2F57E9;
            font-family: SemiBold;

            &.green-color {
              color: rgba(0, 173, 48, 1);
            }
          }
        }
      }
    }
  }

  &__chart {
    width: 100%;
    margin-bottom: 15px;
    overflow: hidden;

    &__canvas {
      position: relative;
      left: -30px;
      height: 120px !important;
      width: calc(100% + 60px) !important;
      max-width: none !important;
      height: 110px;
    }
  }

  &__button {
    padding: 0 17px 60px;
  }
}
</style>
