<template>
  <Container
    class="chart"
    :preloader="preloader"
    style="overflow: hidden !important;"
  >
    <template #content>
      <div class="chart__start">
        <div class="chart__container">
          <p class="chart__label">
            {{ $t('components.ui.pages.charts.balance.balance') }}
          </p>

          <h3>
            ${{ $format.wallet(data.balance) }}
          </h3>
        </div>

        <div class="chart__container mb">
          <p class="little-text">
            {{ $t('components.ui.pages.charts.balance.min') }}:
          </p>

          <p class="val">
            $ {{ $format.wallet(data.min_withdraw) }}
          </p>
        </div>
      </div>

      <ArrowButton
        :text="$t('components.ui.pages.charts.balance.details')"
        @click="$router.push({ name: 'Balance' })"
      />
    </template>
  </Container>
</template>

<script>
import Container from '@/ui/preloader/ItemPreloaderContainer.vue'
import ArrowButton from '@/ui/buttons/ArrowButton.vue'

export default {
  components: {
    Container,
    ArrowButton
  },
  data () {
    return {
      mountFetch: false,
      preloader: true,
      data: {}
    }
  },
  activated () {
    if (this.mountFetch) {
      this.fetch()
    }
  },
  mounted () {
    this.fetch()
  },
  methods: {
    async fetch () {
      try {
        const resp = await this.$axios.get(
          '/private/widgets/balance')

        if (resp.status === 200) {
          this.data = resp.data.data
        }
      } catch (err) {
        throw new Error(err)
      } finally {
        this.preloader = false
        this.mountFetch = true
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.chart {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px;

  @media screen and (max-width: 768px) {
    height: 200px;
    padding: 15px;
  }

  p.chart__label {
    color: rgb(var(--color-dark));
    font-size: 16px;
    font-family: Medium;
  }

  h3 {
    font-family: SemiBold;
    line-height: 100%;

    @media screen and (max-width: 768px) {
      font-size: 20px;
    }
  }

  p.little-text {
    line-height: 100%;
    color: rgb(var(--text-color-light-gray));
    font-family: Medium;
  }

  p.val {
    margin-top: 2px;
    color: rgb(var(--color-green));
    line-height: 100%;
    font-size: 18px;
    font-family: SemiBold;
  }

  .chart__container {
    @media screen and (max-width: 768px) {
      margin-bottom: 8px;
    }

    &.mb {
      margin-top: 20px;

      @media screen and (max-width: 768px) {
        margin: 0 !important;
      }
    }
  }

  button {
    margin-top: 15px;
  }
}
</style>
