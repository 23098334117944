<template>
  <div class="grid">
    <Stats
      class="size-2to1"
    />

    <Balance
      class="size-1to1"
    />

    <Income
      class="size-1to1"
    />

    <BalanceHistory
      class="size-2to2"
    />

    <Promoution
      class="size-2to2"
    />
  </div>
</template>

<script>
import BalanceHistory from '@/modules/main/BalanceHistory.vue'
import Balance from '@/modules/main/Balance.vue'
import Income from '@/modules/main/Income.vue'
import Stats from '@/modules/main/Stats.vue'
import Promoution from '@/modules/main/Promoution.vue'

export default {
  components: {
    BalanceHistory,
    Balance,
    Income,
    Stats,
    Promoution
  }
}
</script>

<style lang="scss" scoped>
.grid {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;

  .size-2to2 {
    grid-column: span 2;
    grid-row: span 2;
  }

  .size-2to1 {
    grid-column: span 2;
    grid-row: span 1;
  }
}

@media screen and (max-width: 1400px) {
  .grid {
    grid-template-columns: repeat(2, 1fr);
  }
}
</style>
