<template>
  <div
    v-if="data.length > 0"
    class="promotion-swiper"
  >
    <Swiper
      :modules="modules"
      :slides-per-view="1"
      :space-between="18"
      :loop="data.length > 1 && true || false"
      :lazy="true"
      :grabCursor="true"
      :pagination="{
        el: '.swiper-pagination',
        clickable: true
      }"
      :auto-destroy="true"
      :delete-instance-on-destroy="true"
      :cleanup-styles-on-destroy="true"
      @swiper="onSwiper"
      @mouseenter="clearIntervalScrolling()"
      @mouseleave="startIntervalScrolling()"
      class="promotion-swiper__container"
    >
      <SwiperSlide
        v-for="item in data"
        :key="item.id"
        class="promotion-swiper__container__slide"
      >
        <img
          :src="!$store.state.window.to768 ? item.image_background_url : item.image_background_mobile_url"
          class="promotion-swiper__container__slide__background"
        >

        <img
          v-if="!$store.state.window.to768"
          :src="item.image_right_url"
          class="promotion-swiper__container__slide__right"
        >

        <div class="promotion-swiper__container__slide__content">
          <img
            :src="!$store.state.window.to768 ? item.image_left_url : item.image_left_mobile_url"
            class="promotion-swiper__container__slide__content__image"
          >

          <div class="promotion-swiper__container__slide__content__control">
            <button
              v-if="item.action_type === 'popup'"
              class="promotion-swiper__container__slide__content__button"
              :style="{
                background: item.button_background_color,
                color: item.button_text_color
              }"
              @click="popUpEvent(item.action_value)"
            >
              {{ item.button_name }}
            </button>

            <template
              v-else-if="item.action_type === 'link'"
            >
              <a
                v-if="isHref(item.action_value)"
                :href="item.action_value"
                target="_blank"
                class="promotion-swiper__container__slide__content__button"
                :style="{
                  background: item.button_background_color,
                  color: item.button_text_color
                }"
              >
                {{ item.button_name }}
              </a>

              <RouterLink
                v-else
                :to="`/${$i18n.locale}${item.action_value}`"
                class="promotion-swiper__container__slide__content__button"
                :style="{
                  background: item.button_background_color,
                  color: item.button_text_color
                }"
              >
                {{ item.button_name }}
              </RouterLink>
            </template>
          </div>
        </div>
      </SwiperSlide>

      <template
        v-if="data.length > 0"
        v-slot:container-end
      >
        <nav class="promotion-swiper__container__nav">
          <div class="promotion-swiper__container__nav__pagination swiper-pagination" />
        </nav>
      </template>
    </Swiper>
  </div>
</template>

<script>
import { Swiper } from 'swiper/vue/swiper'
import { SwiperSlide } from 'swiper/vue/swiper-slide'
import SwiperCore, { Pagination } from 'swiper'

import 'swiper/swiper.min.css'
import 'swiper/modules/pagination/pagination.min.css'

SwiperCore.use([Pagination])

export default {
  components: {
    Swiper,
    SwiperSlide
  },
  data () {
    return {
      mountFetch: false,
      data: [],
      modules: [Pagination],
      swiper: null,
      swiperInterval: null
    }
  },
  activated () {
    if (this.mountFetch) {
      this.fetch()
    }
  },
  mounted () {
    this.fetch()
  },
  beforeUnmount () {
    clearInterval(this.swiperInterval)
  },
  methods: {
    async fetch () {
      try {
        const resp = await this.$axios.get(
          '/private/account-banners')

        if (resp.status === 200) {
          this.data = resp.data.data

          this.startIntervalScrolling()
        }
      } catch (err) {
        throw new Error(err)
      } finally {
        this.mountFetch = true
      }
    },
    popUpEvent (action) {
      if (action) {
        const parse = action.split('/')

        const name = parse[0]
        const id = parse[1]

        this.$pop.up({ name, id })
      }
    },
    onSwiper (event) {
      this.swiper = event
    },
    startIntervalScrolling () {
      if (this.data.length > 1) {
        this.swiperInterval = setInterval(() => {
          this.swiper.slideNext()
        }, 4000)
      }
    },
    clearIntervalScrolling () {
      clearInterval(this.swiperInterval)
    },
    isHref (link) {
      if (link.indexOf('https://') !== -1) {
        return true
      }

      return false
    }
  }
}
</script>

<style lang="scss" scoped>
.promotion-swiper {
  width: 100%;
  max-width: calc(100vw - 392px);
  margin-top: 30px;

  .promotion-swiper__container {
    width: 100%;
    margin: 0;

    .promotion-swiper__container__slide {
      position: relative;
      height: 240px;
      border-radius: 15px;
      overflow: hidden !important;

      img.promotion-swiper__container__slide__background {
        position: absolute;
        right: 0;
        height: 100%;
        width: 100%;
        border-radius: inherit;
        object-position: right;
        object-fit: cover;
      }

      .promotion-swiper__container__slide__right {
        position: absolute;
        top: 0;
        right: 0;
        height: 100%;
      }

      .promotion-swiper__container__slide__content {
        position: relative;
        height: 100%;
        padding: 30px;
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        z-index: 2;

        .promotion-swiper__container__slide__content__control {
          width: 100%;
        }

        img.promotion-swiper__container__slide__content__image {
          height: 100%;
          max-height: 110px;
          object-fit: contain;
        }

        .promotion-swiper__container__slide__content__button {
          height: 46px;
          min-height: 46px;
          width: 100%;
          max-width: 250px;
          margin-top: 25px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 30px;
          text-decoration: none;
          font-size: 14px;
          line-height: 100%;
          font-family: SemiBold;
          text-transform: uppercase;
          transition: .2s;

          &:hover {
            opacity: .9;
          }
        }
      }
    }

    .promotion-swiper__container__nav {
      width: 100%;
      margin-top: 5px;
      display: flex;
      justify-content: center;

      .promotion-swiper__container__nav__pagination {
        position: static;
        height: 15px !important;
        line-height: 100%;
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .promotion-swiper {
    max-width: 100%;

    .promotion-swiper__container {
      .promotion-swiper__container__slide {
        height: 240px;

        .promotion-swiper__container__slide__content {
          padding: 20px 15px;

          img.promotion-swiper__container__slide__content__image {
            max-height: 130px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .promotion-swiper {
    .promotion-swiper__container {
      .promotion-swiper__container__slide {
        .promotion-swiper__container__slide__content {
          .promotion-swiper__container__slide__content__button {
            margin: 25px auto 0;
          }
        }

        img.promotion-swiper__container__slide__background {
          width: auto;
        }
      }
    }
  }
}

@media screen and (max-width: 475px) {
  .promotion-swiper {
    .promotion-swiper__container {
      .promotion-swiper__container__slide {
        .promotion-swiper__container__slide__content {
          .promotion-swiper__container__slide__content__button {
            max-width: 100%;
          }
        }
      }
    }
  }
}
</style>

<style lang="scss">
.promotion-swiper__container__slide {
  position: relative;
  height: 240px;
  border-radius: 15px;
  overflow: hidden !important;
}
</style>
