<template>
  <Container
    class="chart"
    :preloader="preloader"
  >
    <template #content>
      <div class="chart__start">
        <div class="chart__start-grid">
          <div class="chart__start__item">
            <div class="chart__start__item-name">
              {{ $t('components.ui.pages.charts.balance-history.network-wide-procurement') }}
            </div>

            <h3>
              ${{ $format.wallet(data.turnover) }}
            </h3>

            <div class="chart__start__item-before">
              <div class="chart__start__item-before__name">
                {{ $t('components.ui.pages.charts.balance-history.with-last-month') }}
              </div>

              <div class="chart__start__item-before__percent">
                {{ data.delta_turnover }}%
              </div>
            </div>
          </div>

          <div class="chart__start__item">
            <div class="chart__start__item-name green-color">
              {{ $t('components.ui.pages.charts.balance-history.network-wide-turnover') }}
            </div>

            <h3>
              ${{ $format.wallet(data.turnover_products) }}
            </h3>

            <div class="chart__start__item-before">
              <div class="chart__start__item-before__name">
                {{ $t('components.ui.pages.charts.balance-history.with-last-month') }}
              </div>

              <div class="chart__start__item-before__percent green-color">
                {{ data.delta_turnover_products }}%
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="chart__graph__padding">
        <LineChart
          v-if="!preloader"
          class="chart__graph"
          :chart-data="datacollection"
          :options="options"
          :plugins="plugins"
        />
      </div>
    </template>
  </Container>
</template>

<script>
import { Chart, registerables } from 'chart.js'
import vClickOutside from 'click-outside-vue3'

import { LineChart } from 'vue-chart-3'
import Container from '@/ui/preloader/ItemPreloaderContainer.vue'

Chart.register(...registerables)

export default {
  directives: {
    clickOutside: vClickOutside.directive
  },
  components: {
    Container,
    LineChart
  },
  data () {
    return {
      mounFetch: false,
      preloader: true,
      isDatePick: false,
      data: {},

      datacollection: {
        labels: [],
        datasets: [
          {
            data: [],
            borderColor: 'rgba(15, 191, 230, 1)',
            borderWidth: 0.5,
            fill: true,
            backgroundColor: (ctx) => {
              const canvas = ctx.chart.ctx
              const gradient = canvas.createLinearGradient(0, 0, 0, 145)

              gradient.addColorStop(0, 'rgba(15, 191, 230, 0.3)')
              gradient.addColorStop(1, 'rgba(15, 191, 230, 0)')

              return gradient
            },
            borderJoinStyle: 'round',
            tension: 0
          },
          {
            data: [],
            borderColor: 'rgba(0, 178, 7, 1)',
            borderWidth: 0.5,
            fill: true,
            backgroundColor: (ctx) => {
              const canvas = ctx.chart.ctx
              const gradient = canvas.createLinearGradient(0, 0, 0, 145)

              gradient.addColorStop(0, 'rgba(0, 178, 7, 0.3)')
              gradient.addColorStop(1, 'rgba(0, 178, 7, 0)')

              return gradient
            },
            borderJoinStyle: 'round',
            tension: 0
          }
        ]
      },
      options: {
        interaction: {
          intersect: false,
          mode: 'nearest',
          axis: 'x'
        },
        plugins: {
          responsive: true,
          legend: {
            display: false
          },
          title: {
            display: false
          },
          tooltip: {
            callbacks: {
              title: function () {},
              label (tooltipItems) {
                return `$ ${tooltipItems.formattedValue}`
              }
            },
            backgroundColor: 'transparent',
            displayColors: false,
            caretPadding: 1,
            bodyColor: '#787C8D',
            bodyFont: {
              size: 14,
              family: 'Medium'
            }
          }
        },
        elements: {
          point: {
            pointHitRadius: 14,
            pointBorderColor: 'transparent',
            pointBackgroundColor: 'transparent',
            pointHoverBorderColor: '#202539',
            pointHoverBackgroundColor: '#FFFFFF',
            hoverBorderWidth: 3
          },
          line: {
            borderCapStyle: 'round'
          }
        },
        scales: {
          y: {
            display: false,
            beginAtZero: true
          },
          x: {
            min: 0,
            ticks: {
              padding: 10,
              color: 'rgba(120, 124, 141, 1)',
              font: {
                size: 14,
                family: 'Medium'
              }
            },
            grid: {
              display: false,
              drawBorder: false
            }
          }
        }
      },
      plugins: [
        {
          beforeDraw: chart => {
            if (chart.tooltip?._active?.length) {
              const x = chart.tooltip._active[0].element.x
              const yAxis = chart.scales.y
              const ctx = chart.ctx

              ctx.save()
              ctx.beginPath()
              ctx.setLineDash([4, 8])
              ctx.lineWidth = 1
              ctx.strokeStyle = '#787C8D'
              ctx.moveTo(x, yAxis.top)
              ctx.lineTo(x, yAxis.bottom)
              ctx.stroke()
              ctx.restore()
            }
          }
        }]
    }
  },
  activated () {
    if (this.mounFetch) {
      this.fetch(false)
    }
  },
  mounted () {
    this.fetch()
    this.mounFetch = true
  },
  computed: {
    isMonthlyGraph () {
      const monthly = this.data?.graph?.monthly
      var value

      if (monthly) {
        for (const i in monthly) {
          value.push(monthly[i])
        }
      }

      return value?.lenght >= 2
    }
  },
  methods: {
    async fetch (isCacheFetch = true) {
      try {
        if (isCacheFetch) {
          this.preloader = true
        }

        const { status, data } = await this.$axios.get(
          'private/widgets/turnover')

        if (status === 200) {
          this.data = data.data
          this.setGraphData(isCacheFetch)
        }
      } catch (err) {
        throw new Error(err)
      } finally {
        setTimeout(() => {
          this.preloader = false
        }, 200)
      }
    },
    setGraphData (isCacheFetch = true) {
      if (isCacheFetch) {
        this.preloader = true
      }

      const graph = this.data.graph
      const turnover = graph.turnover
      const turnoverProducts = graph.turnover_products

      const labels = []

      const turnoverDate = []
      const turnoverProductsDate = []

      for (const i in turnover) {
        turnoverDate.push(turnover[i] || 0)
        turnoverProductsDate.push(turnoverProducts[i] || 0)
      }

      for (const i in turnover) {
        labels.push(new Intl.DateTimeFormat(this.$i18n.locale, { day: '2-digit' }).format(new Date(i)))
      }

      labels.unshift('')
      labels.push('')

      turnoverDate.unshift(turnoverDate[0])
      turnoverDate.push(turnoverDate[turnoverDate.length - 1])
      turnoverProductsDate.unshift(turnoverProductsDate[0])
      turnoverProductsDate.push(turnoverProductsDate[turnoverProductsDate.length - 1])

      this.datacollection.labels = labels
      this.datacollection.datasets[0].data = turnoverDate
      this.datacollection.datasets[1].data = turnoverProductsDate

      setTimeout(() => {
        this.preloader = false
      }, 500)
    }
  }
}
</script>

<style lang="scss" scoped>
.chart {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .chart__start {
    padding: 10px 20px 0;

    .chart__start-grid {
      padding-bottom: 10px;
      display: flex;
      border-bottom: 1px solid rgba($color: #000000, $alpha: .2);
    }

    .chart__start__item {
      width: 100%;
      padding: 20px 0 10px;

      &:first-child {
        margin-right: 30px;
        padding-right: 30px;
        border-right: 1px solid rgba($color: #000000, $alpha: .2);
      }

      &-name {
        font-size: 16px;
        line-height: 100%;
        color: #0031FF;
        font-family: Medium;

        &.green-color {
          color: rgba(0, 178, 7, 1);
        }
      }

      h3 {
        margin: 15px 0 20px;
        line-height: 100%;
        color: #202539;
        font-family: SemiBold;
      }

      &-before {
        &__name {
          font-size: 12px;
          line-height: 100%;
          color: #787C8D;
          font-family: Medium;
        }

        &__percent {
          margin-top: 5px;
          font-size: 16px;
          line-height: 100%;
          color: #0031FF;
          font-family: SemiBold;

          &.green-color {
            color: rgba(0, 178, 7, 1);
          }
        }
      }
    }
  }

  .chart__graph__padding {
    position: relative;
    bottom: 10px;
    overflow: hidden;
  }

  .chart__graph {
    position: relative;
    left: -15px;
    width: calc(100% + 35px) !important;
    max-width: none !important;
    height: 195px;
    margin-top: 20px;
  }
}
</style>
