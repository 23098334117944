<template>
  <a
    :href="path"
    target="_blank"
    class="secret-card"
    :style="{
      background: value.box_color
    }"
  >
    <div class="secret-card__top">
      <div class="secret-card__top__cashback">
        {{ $t('components.ui.pages.store.secret-box.card.cashback', { value: value.cashback }) }}
      </div>

      <div class="secret-card__top__name">
        {{ value.name }}
      </div>
    </div>

    <img
      class="secret-card__image"
      :src="value.image_url"
    />

    <div class="secret-card__bottom">
      <div class="secret-card__bottom__price">
        ${{ $format.wallet(value.cost) }}
      </div>

      <LightButton
        :text="$t('components.ui.pages.store.secret-box.card.detail')"
        class="blue uppercase"
      />
    </div>
  </a>
</template>

<script>
import LightButton from '@/ui/buttons/LightButton.vue'

export default {
  components: {
    LightButton
  },
  props: {
    value: {
      type: Object,
      required: true
    }
  },
  computed: {
    path () {
      const store = process.env.VUE_APP_STORE_LINK
      const locale = this.$i18n.locale

      return `${store}/${locale === 'en' ? '' : `${locale}/`}categories/boxes/p/${this.value.slug}`
    }
  }
}
</script>

<style lang="scss" scoped>
.secret-card {
  height: 100%;
  min-height: 400px;
  padding: 20px 15px 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: #E5FF98;
  border-radius: 15px;
  text-decoration: none;
  overflow: hidden;

  &:hover {
    &__image {
      transform: scale(1.05);
    }
  }

  &__top {
    &__cashback {
      padding: 5px 8px;
      display: inline-block;
      background: #F13C3C;
      border-radius: 5px;
      font-size: 14px;
      color: #FFFFFF;
      font-family: SemiBold;
    }

    &__name {
      margin-top: 20px;
      font-size: 30px;
      line-height: 100%;
      color: #202539;
      font-family: Medium;
    }
  }

  &__image {
    object-fit: contain;
    transition: .2s;
  }

  &__bottom {
    display: grid;
    grid-template-columns: 90px auto;
    gap: 10px;

    &__price {
      height: 36px;
      width: 90px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #FFFFFF;
      border-radius: 30px;
      font-size: 16px;
      color: #202539;
      font-family: SemiBold;
    }
  }
}
</style>
