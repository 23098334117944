<template>
  <div class="charts">
    <div class="charts__grid">
      <Balance
        style="height: 100%;"
      />

      <Income
        style="height: 100%;"
      />
    </div>

    <Stats
      style="height: 100%"
    />
  </div>
</template>

<script>
import Stats from '@/modules/main/Stats.vue'
import Balance from '@/modules/main/Balance.vue'
import Income from '@/modules/main/Income.vue'

export default {
  components: {
    Balance,
    Income,
    Stats
  }
}
</script>

<style lang="scss" scoped>
.charts {
  position: relative;
  top: -30px;
  width: 100%;
  padding: 0 17px;
  z-index: 102;

  .charts__grid {
    margin-bottom: 10px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
  }
}
</style>
