<template>
  <div
    v-if="value.length"
    class="secret-box"
  >
    <h2>
      {{ $t('components.ui.pages.store.secret-box.name') }}
    </h2>

    <div class="secret-box__list">
      <Card
        v-for="item in value"
        :key="item.id"
        :value="item"
      />
    </div>

    <div class="secret-box__buttons">
      <BlueButton
        tag="a"
        :href="_linkToAll"
        :text="$t('components.ui.pages.store.secret-box.all')"
        style="max-width: 600px;"
      />
    </div>
  </div>
</template>

<script>
import Card from '@/modules/store/secret-box/Card.vue'
import BlueButton from '@/ui/buttons/BlueButton.vue'

export default {
  components: {
    Card,
    BlueButton
  },
  data () {
    return {
      value: []
    }
  },
  computed: {
    _linkToAll () {
      const locale = this.$i18n.locale
      return `${process.env.VUE_APP_STORE_LINK}/${locale === 'en' ? '' : `${locale}/`}categories/boxes`
    }
  },
  mounted () {
    this.fetch()
  },
  methods: {
    async fetch () {
      try {
        const { status, data } = await this.$axiosStore.get('shared/products/?limit=4&box=true')

        if (status === 200) {
          this.value = data?.data
        }
      } catch (err) {
        throw new Error(err)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.secret-box {
  width: 100%;
  margin-top: 40px;

  h2 {
    font-size: 32px;
    line-height: 100%;
    font-family: SemiBold;
    color: #202539;
  }

  &__list {
    width: 100%;
    margin: 20px 0 40px;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;

    @media screen and (max-width: 1600px) {
      grid-template-columns: repeat(3, 1fr);
    }

    @media screen and (max-width: 1280px) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media screen and (max-width: 768px) {
      gap: 15px;
    }

    @media screen and (max-width: 650px) {
      grid-template-columns: repeat(1, 1fr);
    }
  }

  &__buttons {
    width: 100%;
    display: flex;
    justify-content: center;

    a {
      @media screen and (max-width: 768px) {
        max-width: none !important;
      }
    }
  }
}
</style>
