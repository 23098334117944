<template>
  <Container
    v-if="!preloader && !!data"
    class="promoution"
    :preloader="preloader"
    style="overflow: hidden !important;"
  >
    <template #content>
      <Swiper
        :modules="modules"
        :slides-per-view="1"
        :space-between="30"
        :loop="false"
        :lazy="true"
        :grabCursor="true"
        :navigation="{
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        }"
        :pagination="{
          el: '.swiper-pagination',
          clickable: true
        }"
        :auto-destroy="true"
        :delete-instance-on-destroy="true"
        :cleanup-styles-on-destroy="true"
        @swiper="onSwiper"
        class="promoution__swiper"
      >
        <SwiperSlide
          v-for="item in data"
          :key="item.id"
        >
          <h3 class="title">
            {{ item.name }}
          </h3>

          <div
            v-if="item.variable_type === 'referral_with_purchase'"
            class="type-container"
          >
            <div class="info">
              <div
                class="info__styled__text"
                v-html="item.text"
              />

              <div class="info__perfomance">
                <div class="label">
                  {{ $t('components.ui.pages.charts.promoution.perfomance') }}
                </div>

                <h3>
                  {{ item.progress }}/{{ item.rewards[0]?.value }}

                  <span>
                    {{ $t('components.ui.pages.charts.promoution.clients') }}
                  </span>
                </h3>

                <div class="client__list">
                  <svg
                    v-for="user in item.rewards[0].value"
                    :key="user"
                    width="16" height="16"
                    viewBox="0 0 16 16" fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    :class="{ active : user <= item.progress }"
                  >
                    <path d="M10.806 7.85556C10.0074 8.50092 8.99167 8.88809 7.88727 8.88809C6.78287 8.88809 5.76718 8.50092 4.96838 7.85547C3.21881 8.8674 2.03906 10.7593 2.03906 12.9217V15.0381C2.03906 15.2886 2.24213 15.4918 2.49271 15.4918H13.2817C13.5322 15.4918 13.7352 15.2886 13.7352 15.0381V12.9217C13.7352 10.7594 12.5555 8.86749 10.806 7.85556Z" fill="#2F57E9" fill-opacity="0.3"/>
                    <path d="M11.6335 4.23669C11.6335 2.172 9.95383 0.492188 7.88904 0.492188C5.82434 0.492188 4.14453 2.172 4.14453 4.23669C4.14453 6.30139 5.82434 7.9812 7.88904 7.9812C9.95383 7.9812 11.6335 6.30139 11.6335 4.23669Z" fill="#2F57E9" fill-opacity="0.3"/>
                  </svg>
                </div>

                <div
                  v-if="item.end_at && item.show_datas"
                  class="info__perfomance__date"
                >
                  <div class="info__perfomance__date__item">
                    {{ $t('components.ui.pages.charts.promoution.until') }} {{ formatDate(item.end_at) }}
                  </div>

                  <div
                    v-if="item.networkers"
                    class="info__perfomance__date__item opacity"
                  >
                    {{ $t('components.ui.pages.charts.promoution.next') }} {{ formatDate(item.end_at) }}
                  </div>
                </div>
              </div>
            </div>

            <div class="progress">
              <VeProgress
                :progress="_getPercent(item.rewards[0].value, item.progress)"
                :size="250"
                :thickness="8"
                empty-thickness="8px"
                :color="'rgba(47, 87, 233, 1)'"
                empty-color="#CAD2EB"
                class="progress__circle"
              >
                <template #default>
                  <img
                    src="@/static/images/global.png"
                    class="progress__circle__content__global"
                  >

                  <div class="progress__circle__content">
                    <p class="label">
                      {{ $t('components.ui.pages.charts.promoution.bonus') }}
                    </p>

                    <svg width="70" height="70" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M20 0H40V20L20 40H0V20L20 0Z" fill="#2F57E9" fill-opacity="0.2"/>
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M40 40H20L0 60V80H20L40 60V80H60L80 60V40H60L80 20V0H60L40 20V40ZM40 40H60L40 60V40Z" fill="#2F57E9" fill-opacity="0.2"/>
                      <g clip-path="url(#clip0_3176_29057)">
                      <path d="M63.4163 69.9601H16.5844C15.3575 69.9601 14.3633 68.9659 14.3633 67.739V31.9277H65.6378V67.739C65.6378 68.9659 64.6431 69.9601 63.4163 69.9601Z" fill="#FF8A00"/>
                      <path d="M14.3633 31.9277H65.6378V39.3797H14.3633V31.9277Z" fill="#D76200"/>
                      <path d="M70 24.1598V33.392C70 34.378 69.1998 35.1768 68.2138 35.1768H11.7848C10.7988 35.1768 10 34.378 10 33.392V24.1598C10 23.1738 10.7988 22.375 11.7848 22.375H68.2138C69.1998 22.375 70 23.1738 70 24.1598Z" fill="#FF8A00"/>
                      <path d="M37.9621 26.6195C37.5726 27.0732 37.1903 27.5332 36.8173 27.9983C35.427 29.7282 34.1544 31.5409 33.0041 33.4223C32.6502 34.0009 32.3087 34.5855 31.9773 35.1769H19.8086C20.5195 33.5638 21.2991 31.9804 22.1436 30.4327C23.6753 27.6248 25.4231 24.9304 27.3759 22.3751C27.8423 21.7649 28.3198 21.162 28.8091 20.5683C29.1021 20.2104 29.4001 19.8574 29.7022 19.5059C30.8128 20.4626 31.9224 21.4184 33.0334 22.3751C33.2055 22.5225 33.3776 22.6718 33.5493 22.8196C34.8603 23.9485 36.1714 25.0768 37.4819 26.2071C37.6426 26.3435 37.8019 26.4822 37.9621 26.6195Z" fill="#D76200"/>
                      <path d="M56.1172 35.1769H43.9476C43.6175 34.5855 43.2747 34.0009 42.9208 33.4223C41.7704 31.5409 40.4965 29.7282 39.1076 27.9983C38.7332 27.5332 38.351 27.0732 37.9609 26.6195C38.1216 26.4822 38.2809 26.3444 38.4416 26.2071C39.7535 25.0768 41.0646 23.9471 42.3752 22.8196C42.5473 22.6718 42.7194 22.5225 42.8915 22.3751C44.002 21.4184 45.113 20.4626 46.2227 19.5059C46.5248 19.8565 46.8228 20.2104 47.1158 20.5674C47.606 21.162 48.0839 21.7649 48.549 22.3751C50.5018 24.9304 52.2496 27.6248 53.7822 30.4327C54.6267 31.9804 55.4063 33.5638 56.1172 35.1769Z" fill="#D76200"/>
                      <path d="M39.9993 26.62C39.6093 27.0732 39.2271 27.5332 38.854 27.9983C37.4642 29.7282 36.1912 31.5409 35.0408 33.4228C31.3888 39.3925 28.9631 46.0612 27.94 53.0412C26.4024 51.0018 24.9833 48.7954 23.7066 46.4361C21.4608 47.9009 19.2658 49.5764 17.1523 51.4614C18.2377 44.0576 20.6291 36.9412 24.1808 30.4327C26.0778 26.9546 28.3071 23.65 30.8459 20.5683C31.1389 20.2104 31.4369 19.8579 31.739 19.5059C33.0216 20.6104 34.3034 21.715 35.5865 22.8196C36.8971 23.9485 38.2081 25.0768 39.5191 26.2071C39.6794 26.3435 39.8387 26.4822 39.9993 26.62Z" fill="#436BFD"/>
                      <path d="M62.8479 51.4614C60.7344 49.5764 58.5394 47.9009 56.2936 46.4361C55.0169 48.7954 53.5979 51.0018 52.0602 53.0412C51.0371 46.0612 48.6119 39.3925 44.9594 33.4228C43.8091 31.5409 42.5351 29.7282 41.1462 27.9983C40.7718 27.5332 40.3896 27.0732 40 26.62C40.1602 26.4822 40.3195 26.3444 40.4802 26.2071C41.7921 25.0768 43.1032 23.9471 44.4142 22.8196C45.6969 21.7137 46.98 20.6104 48.2613 19.5059C48.5634 19.8565 48.8614 20.2104 49.1544 20.5674C51.6936 23.6491 53.9229 26.9537 55.8208 30.4327C59.3716 36.9412 61.7625 44.0576 62.8479 51.4614Z" fill="#436BFD"/>
                      <path d="M35.2227 26.6191H44.7766V35.1757H35.2227V26.6191Z" fill="#436BFD"/>
                      <path d="M55.8208 30.4317L44.9594 33.4218C43.8091 31.5399 42.5351 29.7272 41.1462 27.9973L40.4802 26.2061L40 24.9142L44.4142 22.8186L49.1544 20.5664C51.6936 23.6481 53.9229 26.9527 55.8208 30.4317Z" fill="#254DDF"/>
                      <path d="M39.9982 24.9153L39.518 26.2071L38.8528 27.9983C37.4631 29.7282 36.19 31.541 35.0397 33.4228L24.1797 30.4327C26.0767 26.9546 28.306 23.65 30.8447 20.5684L35.5853 22.8196L39.9982 24.9153Z" fill="#254DDF"/>
                      <path d="M57.3341 10.4151L40 18.6471L43.3298 27.6023L61.8317 22.5097C64.2514 21.8436 65.5807 19.2467 64.706 16.8943L63.1789 12.7881C62.3045 10.4357 59.6014 9.33844 57.3341 10.4151Z" fill="#436BFD"/>
                      <path d="M22.6646 10.4151L39.9988 18.6471L36.669 27.6023L18.1676 22.5097C15.7478 21.8436 14.4185 19.2467 15.2933 16.8943L16.8199 12.7881C17.6947 10.4357 20.3973 9.33844 22.6646 10.4151Z" fill="#436BFD"/>
                      <path d="M44.14 22.2469C43.8251 22.2469 43.5161 22.0913 43.3343 21.8061C43.0501 21.3616 43.1796 20.7711 43.6241 20.4868C43.7555 20.4031 46.8774 18.4164 50.4617 16.9666C55.6289 14.8765 59.0796 14.9886 60.7165 17.2999C61.0219 17.7306 60.9198 18.3271 60.489 18.632C60.0583 18.9369 59.4623 18.8352 59.1574 18.4045C56.9381 15.2715 47.711 20.1431 44.6536 22.0968C44.4943 22.1984 44.3162 22.2469 44.14 22.2469Z" fill="#254DDF"/>
                      <path d="M35.7643 22.2469C35.588 22.2469 35.41 22.1984 35.2507 22.0968C32.1933 20.1431 22.9661 15.2715 20.7469 18.4045C20.4416 18.8352 19.8456 18.9369 19.4148 18.632C18.9845 18.3267 18.8824 17.7306 19.1877 17.2999C20.8247 14.9886 24.2749 14.8765 29.4426 16.9666C33.0269 18.4159 36.1488 20.4031 36.2802 20.4868C36.7247 20.7711 36.8542 21.3616 36.5704 21.8061C36.3878 22.0913 36.0792 22.2469 35.7643 22.2469Z" fill="#254DDF"/>
                      <path d="M42.7163 15.5684H37.2799C35.7921 15.5684 34.5859 16.7746 34.5859 18.2628V26.1606C34.5859 27.6487 35.7921 28.8549 37.2799 28.8549H42.7163C44.204 28.8549 45.4102 27.6487 45.4102 26.1606V18.2628C45.4102 16.7746 44.204 15.5684 42.7163 15.5684Z" fill="#254DDF"/>
                      <path d="M35.2227 35.1758H44.7766V69.9599H35.2227V35.1758Z" fill="#436BFD"/>
                      <path d="M35.2227 35.1758H44.7766V39.3794H35.2227V35.1758Z" fill="#254DDF"/>
                      </g>
                      <defs>
                      <clipPath id="clip0_3176_29057">
                      <rect width="60" height="60" fill="white" transform="translate(10 10)"/>
                      </clipPath>
                      </defs>
                    </svg>

                    <p class="value">
                      + ${{ $format.wallet(item.rewards[0].reward, ',') }}
                    </p>
                  </div>
                </template>
              </VeProgress>
            </div>
          </div>

          <div
            v-if="item.variable_type === 'turnover_total'"
            class="type-container"
          >
            <div class="info">
              <div
                class="info__styled__text"
                v-html="item.text"
              />

              <div class="info__perfomance">
                <div class="label">
                  {{ $t('components.ui.pages.charts.promoution.perfomance') }}
                </div>

                <h3 class="turnover_total">
                  $ {{ $format.wallet(item.progress) }}/$ {{ $format.wallet(item.rewards[0]?.value) }}
                </h3>

                <div
                  v-if="item.end_at"
                  class="info__perfomance__date"
                >
                  <div class="info__perfomance__date__item">
                    {{ $t('components.ui.pages.charts.promoution.until') }} {{ formatDate(item.end_at) }}
                  </div>

                  <div
                    v-if="item.networkers"
                    class="info__perfomance__date__item opacity"
                  >
                    {{ $t('components.ui.pages.charts.promoution.next') }} {{ formatDate(item.end_at) }}
                  </div>
                </div>
              </div>
            </div>

            <div class="progress">
              <VeProgress
                :progress="_getPercent(item.rewards[0].value, item.progress)"
                :size="250"
                :thickness="8"
                empty-thickness="8px"
                :color="'#FF8A00'"
                empty-color="#CAD2EB"
                class="progress__circle"
              >
                <template #default>
                  <img
                    src="@/static/images/global.png"
                    class="progress__circle__content__global"
                  >

                  <div class="progress__circle__content">
                    <p class="label">
                      {{ $t('components.ui.pages.charts.promoution.bonus') }}
                    </p>

                    <svg width="70" height="70" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M20 0H40V20L20 40H0V20L20 0Z" fill="#2F57E9" fill-opacity="0.2"/>
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M40 40H20L0 60V80H20L40 60V80H60L80 60V40H60L80 20V0H60L40 20V40ZM40 40H60L40 60V40Z" fill="#2F57E9" fill-opacity="0.2"/>
                      <g clip-path="url(#clip0_3176_29057)">
                      <path d="M63.4163 69.9601H16.5844C15.3575 69.9601 14.3633 68.9659 14.3633 67.739V31.9277H65.6378V67.739C65.6378 68.9659 64.6431 69.9601 63.4163 69.9601Z" fill="#FF8A00"/>
                      <path d="M14.3633 31.9277H65.6378V39.3797H14.3633V31.9277Z" fill="#D76200"/>
                      <path d="M70 24.1598V33.392C70 34.378 69.1998 35.1768 68.2138 35.1768H11.7848C10.7988 35.1768 10 34.378 10 33.392V24.1598C10 23.1738 10.7988 22.375 11.7848 22.375H68.2138C69.1998 22.375 70 23.1738 70 24.1598Z" fill="#FF8A00"/>
                      <path d="M37.9621 26.6195C37.5726 27.0732 37.1903 27.5332 36.8173 27.9983C35.427 29.7282 34.1544 31.5409 33.0041 33.4223C32.6502 34.0009 32.3087 34.5855 31.9773 35.1769H19.8086C20.5195 33.5638 21.2991 31.9804 22.1436 30.4327C23.6753 27.6248 25.4231 24.9304 27.3759 22.3751C27.8423 21.7649 28.3198 21.162 28.8091 20.5683C29.1021 20.2104 29.4001 19.8574 29.7022 19.5059C30.8128 20.4626 31.9224 21.4184 33.0334 22.3751C33.2055 22.5225 33.3776 22.6718 33.5493 22.8196C34.8603 23.9485 36.1714 25.0768 37.4819 26.2071C37.6426 26.3435 37.8019 26.4822 37.9621 26.6195Z" fill="#D76200"/>
                      <path d="M56.1172 35.1769H43.9476C43.6175 34.5855 43.2747 34.0009 42.9208 33.4223C41.7704 31.5409 40.4965 29.7282 39.1076 27.9983C38.7332 27.5332 38.351 27.0732 37.9609 26.6195C38.1216 26.4822 38.2809 26.3444 38.4416 26.2071C39.7535 25.0768 41.0646 23.9471 42.3752 22.8196C42.5473 22.6718 42.7194 22.5225 42.8915 22.3751C44.002 21.4184 45.113 20.4626 46.2227 19.5059C46.5248 19.8565 46.8228 20.2104 47.1158 20.5674C47.606 21.162 48.0839 21.7649 48.549 22.3751C50.5018 24.9304 52.2496 27.6248 53.7822 30.4327C54.6267 31.9804 55.4063 33.5638 56.1172 35.1769Z" fill="#D76200"/>
                      <path d="M39.9993 26.62C39.6093 27.0732 39.2271 27.5332 38.854 27.9983C37.4642 29.7282 36.1912 31.5409 35.0408 33.4228C31.3888 39.3925 28.9631 46.0612 27.94 53.0412C26.4024 51.0018 24.9833 48.7954 23.7066 46.4361C21.4608 47.9009 19.2658 49.5764 17.1523 51.4614C18.2377 44.0576 20.6291 36.9412 24.1808 30.4327C26.0778 26.9546 28.3071 23.65 30.8459 20.5683C31.1389 20.2104 31.4369 19.8579 31.739 19.5059C33.0216 20.6104 34.3034 21.715 35.5865 22.8196C36.8971 23.9485 38.2081 25.0768 39.5191 26.2071C39.6794 26.3435 39.8387 26.4822 39.9993 26.62Z" fill="#436BFD"/>
                      <path d="M62.8479 51.4614C60.7344 49.5764 58.5394 47.9009 56.2936 46.4361C55.0169 48.7954 53.5979 51.0018 52.0602 53.0412C51.0371 46.0612 48.6119 39.3925 44.9594 33.4228C43.8091 31.5409 42.5351 29.7282 41.1462 27.9983C40.7718 27.5332 40.3896 27.0732 40 26.62C40.1602 26.4822 40.3195 26.3444 40.4802 26.2071C41.7921 25.0768 43.1032 23.9471 44.4142 22.8196C45.6969 21.7137 46.98 20.6104 48.2613 19.5059C48.5634 19.8565 48.8614 20.2104 49.1544 20.5674C51.6936 23.6491 53.9229 26.9537 55.8208 30.4327C59.3716 36.9412 61.7625 44.0576 62.8479 51.4614Z" fill="#436BFD"/>
                      <path d="M35.2227 26.6191H44.7766V35.1757H35.2227V26.6191Z" fill="#436BFD"/>
                      <path d="M55.8208 30.4317L44.9594 33.4218C43.8091 31.5399 42.5351 29.7272 41.1462 27.9973L40.4802 26.2061L40 24.9142L44.4142 22.8186L49.1544 20.5664C51.6936 23.6481 53.9229 26.9527 55.8208 30.4317Z" fill="#254DDF"/>
                      <path d="M39.9982 24.9153L39.518 26.2071L38.8528 27.9983C37.4631 29.7282 36.19 31.541 35.0397 33.4228L24.1797 30.4327C26.0767 26.9546 28.306 23.65 30.8447 20.5684L35.5853 22.8196L39.9982 24.9153Z" fill="#254DDF"/>
                      <path d="M57.3341 10.4151L40 18.6471L43.3298 27.6023L61.8317 22.5097C64.2514 21.8436 65.5807 19.2467 64.706 16.8943L63.1789 12.7881C62.3045 10.4357 59.6014 9.33844 57.3341 10.4151Z" fill="#436BFD"/>
                      <path d="M22.6646 10.4151L39.9988 18.6471L36.669 27.6023L18.1676 22.5097C15.7478 21.8436 14.4185 19.2467 15.2933 16.8943L16.8199 12.7881C17.6947 10.4357 20.3973 9.33844 22.6646 10.4151Z" fill="#436BFD"/>
                      <path d="M44.14 22.2469C43.8251 22.2469 43.5161 22.0913 43.3343 21.8061C43.0501 21.3616 43.1796 20.7711 43.6241 20.4868C43.7555 20.4031 46.8774 18.4164 50.4617 16.9666C55.6289 14.8765 59.0796 14.9886 60.7165 17.2999C61.0219 17.7306 60.9198 18.3271 60.489 18.632C60.0583 18.9369 59.4623 18.8352 59.1574 18.4045C56.9381 15.2715 47.711 20.1431 44.6536 22.0968C44.4943 22.1984 44.3162 22.2469 44.14 22.2469Z" fill="#254DDF"/>
                      <path d="M35.7643 22.2469C35.588 22.2469 35.41 22.1984 35.2507 22.0968C32.1933 20.1431 22.9661 15.2715 20.7469 18.4045C20.4416 18.8352 19.8456 18.9369 19.4148 18.632C18.9845 18.3267 18.8824 17.7306 19.1877 17.2999C20.8247 14.9886 24.2749 14.8765 29.4426 16.9666C33.0269 18.4159 36.1488 20.4031 36.2802 20.4868C36.7247 20.7711 36.8542 21.3616 36.5704 21.8061C36.3878 22.0913 36.0792 22.2469 35.7643 22.2469Z" fill="#254DDF"/>
                      <path d="M42.7163 15.5684H37.2799C35.7921 15.5684 34.5859 16.7746 34.5859 18.2628V26.1606C34.5859 27.6487 35.7921 28.8549 37.2799 28.8549H42.7163C44.204 28.8549 45.4102 27.6487 45.4102 26.1606V18.2628C45.4102 16.7746 44.204 15.5684 42.7163 15.5684Z" fill="#254DDF"/>
                      <path d="M35.2227 35.1758H44.7766V69.9599H35.2227V35.1758Z" fill="#436BFD"/>
                      <path d="M35.2227 35.1758H44.7766V39.3794H35.2227V35.1758Z" fill="#254DDF"/>
                      </g>
                      <defs>
                      <clipPath id="clip0_3176_29057">
                      <rect width="60" height="60" fill="white" transform="translate(10 10)"/>
                      </clipPath>
                      </defs>
                    </svg>

                    <p class="value">
                      + ${{ $format.wallet(item.rewards[0].reward, ',') }}
                    </p>
                  </div>
                </template>
              </VeProgress>
            </div>
          </div>

          <div
            v-if="item.type === 2"
            class="type-container"
          >
            <div class="info">
              <div class="turnover-item blue-theme">
                <p class="name">
                  Apple Watch 6
                </p>

                <p class="description">
                  8/10 clients, from $100 turnover
                </p>

                <div class="client__list">
                  <svg
                    v-for="item in 10"
                    :key="item"
                    :class="{
                      active: item <= 8
                    }"
                    width="16" height="16"
                    viewBox="0 0 16 16" fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M10.806 7.85556C10.0074 8.50092 8.99167 8.88809 7.88727 8.88809C6.78287 8.88809 5.76718 8.50092 4.96838 7.85547C3.21881 8.8674 2.03906 10.7593 2.03906 12.9217V15.0381C2.03906 15.2886 2.24213 15.4918 2.49271 15.4918H13.2817C13.5322 15.4918 13.7352 15.2886 13.7352 15.0381V12.9217C13.7352 10.7594 12.5555 8.86749 10.806 7.85556Z" />
                    <path d="M11.6335 4.23669C11.6335 2.172 9.95383 0.492188 7.88904 0.492188C5.82434 0.492188 4.14453 2.172 4.14453 4.23669C4.14453 6.30139 5.82434 7.9812 7.88904 7.9812C9.95383 7.9812 11.6335 6.30139 11.6335 4.23669Z" />
                  </svg>
                </div>
              </div>

              <div class="turnover-item purple-theme">
                <p class="name">
                  iPhone 14
                </p>

                <p class="description">
                  2/10 clients, from $500 turnover
                </p>

                <div class="client__list">
                  <svg
                    v-for="item in 10"
                    :key="item"
                    :class="{
                      active: item <= 2
                    }"
                    width="16" height="16"
                    viewBox="0 0 16 16" fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M10.806 7.85556C10.0074 8.50092 8.99167 8.88809 7.88727 8.88809C6.78287 8.88809 5.76718 8.50092 4.96838 7.85547C3.21881 8.8674 2.03906 10.7593 2.03906 12.9217V15.0381C2.03906 15.2886 2.24213 15.4918 2.49271 15.4918H13.2817C13.5322 15.4918 13.7352 15.2886 13.7352 15.0381V12.9217C13.7352 10.7594 12.5555 8.86749 10.806 7.85556Z" />
                    <path d="M11.6335 4.23669C11.6335 2.172 9.95383 0.492188 7.88904 0.492188C5.82434 0.492188 4.14453 2.172 4.14453 4.23669C4.14453 6.30139 5.82434 7.9812 7.88904 7.9812C9.95383 7.9812 11.6335 6.30139 11.6335 4.23669Z" />
                  </svg>
                </div>
              </div>

              <div class="turnover-item orange-theme">
                <p class="name">
                  MacBook Pro 14’
                </p>

                <p class="description">
                  1/10 clients, from $1,000 turnover
                </p>

                <div class="client__list">
                  <svg
                    v-for="item in 10"
                    :key="item"
                    :class="{
                      active: item <= 1
                    }"
                    width="16" height="16"
                    viewBox="0 0 16 16" fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M10.806 7.85556C10.0074 8.50092 8.99167 8.88809 7.88727 8.88809C6.78287 8.88809 5.76718 8.50092 4.96838 7.85547C3.21881 8.8674 2.03906 10.7593 2.03906 12.9217V15.0381C2.03906 15.2886 2.24213 15.4918 2.49271 15.4918H13.2817C13.5322 15.4918 13.7352 15.2886 13.7352 15.0381V12.9217C13.7352 10.7594 12.5555 8.86749 10.806 7.85556Z" />
                    <path d="M11.6335 4.23669C11.6335 2.172 9.95383 0.492188 7.88904 0.492188C5.82434 0.492188 4.14453 2.172 4.14453 4.23669C4.14453 6.30139 5.82434 7.9812 7.88904 7.9812C9.95383 7.9812 11.6335 6.30139 11.6335 4.23669Z" />
                  </svg>
                </div>
              </div>
            </div>

            <div class="progress">
              <VeProgress
                :size="250"
                :thickness="6"
                :line-mode="'out 5'"
                :gap="4"
                :angle="-90"
                empty-thickness="6px"
                empty-color="#CAD2EB"
                :data="[
                  {
                    progress: 80,
                    color: '#2F57E9'
                  },
                  {
                    progress: 20,
                    color: '#945FCD'
                  },
                  {
                    progress: 10,
                    color: '#FF8A00'
                  }
                ]"
                class="progress__circle"
              />

              <div class="progress__circle__content-image">
                <img
                  src="@/static/svg/promoution-progress-gradient.svg"
                  class="gradient"
                >

                <div class="progress__circle__content-image__content">
                  <p class="name">
                    Promoution prizes
                  </p>

                  <img src="@/static/images/promoution-progress-type-2.png">
                </div>
              </div>
            </div>
          </div>

          <div
            v-if="item.type === 3"
            class="type-container"
          >
            <div class="info">
              <div class="turnover-item-decorate blue-theme">
                <p class="text">
                  Turnover in first line

                  <span>
                    $10,000
                  </span>
                </p>

                <p class="name">
                  AirPods Pro
                </p>
              </div>

              <div class="turnover-item-decorate purple-theme">
                <p class="text">
                  Turnover in first line

                  <span>
                    $10,000
                  </span>
                </p>

                <p class="name">
                  AirPods Pro
                </p>
              </div>

              <div class="turnover-item-decorate orange-theme">
                <p class="text">
                  Turnover in first line

                  <span>
                    $10,000
                  </span>
                </p>

                <p class="name">
                  AirPods Pro
                </p>
              </div>

              <div class="turnover-info">
                <p class="text">
                  Turnover in your frist line
                </p>

                <h3>
                  $32,456
                </h3>
              </div>
            </div>

            <div class="progress">
              <VeProgress
                :size="250"
                :thickness="6"
                :line-mode="'out 5'"
                :gap="4"
                :angle="-90"
                empty-thickness="6px"
                empty-color="#CAD2EB"
                :data="[
                  {
                    progress: 75,
                    color: '#2F57E9'
                  },
                  {
                    progress: 25,
                    color: '#945FCD'
                  },
                  {
                    progress: 10,
                    color: '#FF8A00'
                  }
                ]"
                class="progress__circle"
              />

              <div class="progress__circle__content-image">
                <img
                  src="@/static/svg/promoution-progress-gradient.svg"
                  class="gradient"
                >

                <div class="progress__circle__content-image__content">
                  <p class="name">
                    Promoution prizes
                  </p>

                  <img src="@/static/images/promoution-progress-type-2.png">
                </div>
              </div>
            </div>
          </div>

          <div
            v-if="item.type === 4"
            class="type-container"
          >
            <div class="info">
              <div class="draw">
                <p class="text">
                  Make a purchase from

                  <span>
                    $500
                  </span>

                  and take part in the draw
                </p>

                <ul>
                  <li>
                    10 AirPods Pro
                  </li>

                  <li>
                    5 iPhone 14
                  </li>

                  <li>
                    1 MacBook
                  </li>
                </ul>

                <p class="note">
                  The winner is chosen at random
                </p>
              </div>
            </div>

            <div class="progress">
              <VeProgress
                :progress="30"
                :size="250"
                :thickness="8"
                empty-thickness="8px"
                :color="'#FF8A00'"
                empty-color="#CAD2EB"
                class="progress__circle"
              />

              <div class="progress__circle__content-image">
                <img
                  src="@/static/svg/promoution-progress-gradient.svg"
                  class="gradient"
                >

                <div class="progress__circle__content-image__content">
                  <p class="name">
                    Promoution prizes
                  </p>

                  <img src="@/static/images/promoution-progress-type-2.png">
                </div>
              </div>
            </div>
          </div>
        </SwiperSlide>

        <template v-slot:container-end>
          <nav class="promoution__swiper__nav">
            <div class="start">
              <div class="swiper-pagination" />
            </div>

            <div class="buttons">
              <button
                class="prev"
                :disabled="data.length <= 1 "
                @click="swiper.slidePrev()"
              >
                <svg width="9" height="14" viewBox="0 0 9 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M8 1L2 7L8 13" stroke="#202539" stroke-width="2" stroke-linecap="round"/>
                </svg>
              </button>

              <button
                class="next"
                :disabled="data.length <= 1 "
                @click="swiper.slideNext()"
              >
                <svg width="9" height="14" viewBox="0 0 9 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M1 1L7 7L1 13" stroke="#202539" stroke-width="2" stroke-linecap="round"/>
                </svg>
              </button>
            </div>
          </nav>
        </template>
      </Swiper>
    </template>
  </Container>
</template>

<script>
import SwiperCore, { Pagination } from 'swiper'

import Container from '@/ui/preloader/ItemPreloaderContainer.vue'
import { Swiper } from 'swiper/vue/swiper'
import { SwiperSlide } from 'swiper/vue/swiper-slide'
import { VeProgress } from 'vue-ellipse-progress'

import 'swiper/swiper.min.css'
import 'swiper/modules/pagination/pagination.min.css'

SwiperCore.use([Pagination])

export default {
  components: {
    Container,
    Swiper,
    SwiperSlide,
    VeProgress
  },
  data () {
    return {
      mountFetch: false,
      preloader: true,
      data: {},
      swiper: null,
      modules: [Pagination]
    }
  },
  activated () {
    if (this.mountFetch) {
      this.fetch()
    }
  },
  mounted () {
    this.fetch()
  },
  methods: {
    async fetch () {
      try {
        const resp = await this.$axios.get(
          '/private/widgets/promotion')

        if (resp.status === 200) {
          this.data = resp.data?.data ? resp.data.data : false
        }
      } catch (err) {
        throw new Error(err)
      } finally {
        this.preloader = false
        this.mountFetch = true
      }
    },
    _getPercent (max, progress) {
      const percent = progress > max ? max : progress / (max / 100)
      return percent
    },
    onSwiper (swiper) {
      this.swiper = swiper
    },
    formatDate (date) {
      let d

      if (date) {
        d = new Date(date)
      } else {
        d = new Date()
      }

      const mo = new Intl.DateTimeFormat('en', { month: '2-digit' }).format(d)
      const da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(d)

      return `${da}.${mo}`
    }
  }
}
</script>

<style lang="scss" scoped>
.promoution {
  .promoution__swiper {
    height: 100%;
    padding: 30px 20px 20px;
    display: flex;
    flex-direction: column;

    h3.title {
      font-size: 26px;
      line-height: 100%;
      color: #202539;
      font-family: SemiBold;
      z-index: 2;
    }

    .swiper-slide {
      .type-container {
        display: flex;

        .info {
          height: 100%;
          width: 100%;
          margin-right: 10px;
          padding-top: 30px;

          .info__styled__text {
            font-size: 16px;
            line-height: 150%;
            color: #202539;

            span {
              font-family: Bold;
              color: rgba(47, 87, 233, 1);
            }
          }

          .info__perfomance {
            margin-top: 30px;

            .label {
              font-size: 12px;
              line-height: 100%;
              color: #787C8D;
              font-family: Medium;
            }

            h3 {
              margin: 10px 0;
              line-height: 100%;
              color: #202539;
              font-family: SemiBold;

              span {
                font-size: 16px;
                font-family: inherit;
              }

              &.turnover_total {
                font-size: 22px;
              }
            }

            .client__list {
              display: flex;
              flex-wrap: wrap;

              svg {
                margin-right: 2px;
                margin-bottom: 2px;

                &.active {
                  path {
                    fill-opacity: 1;
                    fill: #2F57E9;
                  }
                }
              }
            }

            .info__perfomance__date {
              margin-top: 20px;
              display: flex;
              gap: 5px;

              .info__perfomance__date__item {
                padding: 4px 6px;
                background: #D3FF53;
                border-radius: 4px;
                font-size: 12px;
                line-height: 100%;
                color: #202539;
                font-family: SemiBold;

                &.opacity {
                  background: #EAEEFD;
                  color: #787C8D;
                }
              }
            }
          }

          .turnover-item {
            margin-bottom: 20px;

            &:last-child {
              margin: 0;
            }

            p.name {
              font-family: SemiBold;
              font-size: 16px;
              line-height: 100%;
              color: #202539;
            }

            p.description {
              margin: 5px 0;
              font-family: Medium;
              font-size: 14px;
              line-height: 100%;
              color: #202539;
            }

            .client__list {
              height: 16px;
              display: flex;
              flex-wrap: wrap;

              svg {
                margin-right: 2px;
                margin-bottom: 2px;
                opacity: .3;

                &.active {
                  opacity: 1;
                }
              }
            }

            &.blue-theme {
              .client__list {
                svg path {
                  fill: #2F57E9;
                }
              }
            }

            &.purple-theme {
              .client__list {
                svg path {
                  fill: #945FCD;
                }
              }
            }

            &.orange-theme {
              .client__list {
                svg path {
                  fill: #FF8A00;
                }
              }
            }
          }

          .turnover-item-decorate {
            margin-bottom: 20px;

            p.name {
              margin-top: 4px;
              font-size: 16px;
              line-height: 100%;
              color: #787C8D;
              font-family: SemiBold;
            }

            p.text {
              font-size: 14px;
              line-height: 100%;
              color: #202539;
              font-family: Medium;

              span {
                font-family: Bold;
              }
            }

            &.blue-theme {
              p.text {
                span {
                  color: #2F57E9;
                }
              }
            }

            &.purple-theme {
              p.text {
                span {
                  color: #945FCD;
                }
              }
            }

            &.orange-theme {
              p.text {
                span {
                  color: #FF8A00;
                }
              }
            }
          }

          .turnover-info {
            p.text {
              margin-bottom: 5px;
              font-size: 16px;
              line-height: 100%;
              color: #202539;
              font-family: Medium;
            }

            h3 {
              line-height: 100%;
              color: #202539;
              font-family: SemiBold;
            }
          }

          .draw {
            p.text {
              font-size: 16px;
              line-height: 150%;
              color: #202539;

              span {
                font-family: Bold;
                color: rgba(47, 87, 233, 1);
              }
            }

            ul {
              padding-left: 30px;

              li {
                font-family: Medium;
              }
            }

            p.note {
              font-size: 12px;
              line-height: 100%;
              color: #787C8D;
              font-family: Medium;
            }
          }
        }

        .progress {
          position: relative;
          height: 100%;
          min-width: 250px;

          .progress__circle {
            .progress__circle__content__global {
              position: absolute;
              top: -105px;
              left: -105px;
              height: 210px;
              width: 210px;
              min-width: 210px;
            }

            .progress__circle__content {
              position: absolute;
              top: -90px;
              left: -90px;
              height: 180px;
              width: 180px;
              padding: 30px 20px 20px;
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: space-between;
              border-radius: 50%;
              z-index: 2;
              background: #F0F2F8;

              p.label {
                font-size: 12px;
                line-height: 100%;
                text-align: center;
                color: #787C8D;
                font-family: Medium;
              }

              p.value {
                font-size: 18px;
                line-height: 100%;
                text-align: center;
                color: #202539;
                font-family: SemiBold;
              }
            }
          }

          .progress__circle__content-image {
            position: absolute;
            top: 35px;
            left: 35px;
            height: 180px;
            width: 180px;
            border-radius: 50%;
            background: #EAEEFD;
            overflow: hidden;

            img.gradient {
              position: absolute;
              height: 100%;
              width: 100%;
              object-fit: cover;
            }

            .progress__circle__content-image__content {
              position: relative;
              padding-top: 25px;
              display: flex;
              flex-direction: column;
              align-items: center;
              z-index: 2;

              p.name {
                max-width: 90px;
                margin-bottom: 10px;
                font-size: 12px;
                line-height: 100%;
                text-align: center;
                color: #787C8D;
              }
            }
          }
        }
      }
    }

    nav {
      width: 100%;
      margin-top: 30px;
      padding-top: 20px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-top: 1px solid rgba(156, 163, 184, .6);

      .swiper-pagination {
        position: static;
        height: auto;
        width: auto;
      }

      .buttons {
        display: flex;

        button {
          height: 36px;
          width: 36px;
          padding: 0 13px;
          display: flex;
          align-items: center;
          border-radius: 50%;
          background: rgba(47, 87, 233, .2);
          transition: .2s;
          cursor: pointer;

          svg {
            margin: 0 auto !important;

            path {
              transition: .2s;
            }
          }

          &.prev {
            margin-right: 8px;
          }

          &:hover {
            background: #577FFF;

            svg path {
              stroke: #FFFFFF;
            }
          }

          &:active {
            background: #586BB1;

            svg path {
              stroke: #FFFFFF;
            }
          }

          &:disabled {
            background: rgba(47, 87, 233, .2);
            cursor: default;

            svg path {
              stroke: #9CA3B8;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .promoution {
    .promoution__swiper {
      height: 100%;
      padding: 20px 15px 15px;
      display: flex;
      flex-direction: column;

      .swiper-slide {
        .type-container {
          display: flex;

          .info {
            margin-right: 0;
            padding-top: 20px;

            .info__perfomance {
              margin-top: 20px;
            }

            .turnover-item-decorate {
              margin-bottom: 10px;
              padding-bottom: 10px;
              display: flex;
              align-items: center;
              justify-content: space-between;
              border-bottom: 1px solid rgba(47, 87, 233, 0.2);
            }

            .turnover-info {
              margin-top: 20px;

              p.text {
                margin-bottom: 5px;
                font-size: 16px;
                line-height: 100%;
                color: #202539;
                font-family: Medium;
              }

              h3 {
                line-height: 100%;
                color: #202539;
                font-family: SemiBold;
              }
            }

            .draw {
              p.text {
                font-size: 16px;
                line-height: 150%;
                color: #202539;

                span {
                  font-family: Bold;
                  color: rgba(47, 87, 233, 1);
                }
              }

              ul {
                padding-left: 30px;

                li {
                  font-family: Medium;
                }
              }

              p.note {
                font-size: 12px;
                line-height: 100%;
                color: #787C8D;
                font-family: Medium;
              }
            }
          }
        }
      }

      nav {
        margin-top: 25px;
        padding-top: 15px;
      }
    }
  }
}

@media screen and (max-width: 560px) {
  .promoution {
    .promoution__swiper {
      padding: 30px 20px 20px;

      h3.title {
        margin-bottom: 30px;
      }

      .swiper-slide {
        .type-container {
          flex-direction: column-reverse;

          .progress {
            width: 100%;
            display: flex;
            justify-content: center;

            .progress__circle {
              .progress__circle__content__global {
                position: absolute;
                top: -105px;
                left: -105px;
                height: 210px;
                width: 210px;
                min-width: 210px;
              }

              .progress__circle__content {
                position: absolute;
                top: -90px;
                left: -90px;
                height: 180px;
                width: 180px;
                padding: 30px 20px 20px;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: space-between;
                border-radius: 50%;
                z-index: 2;
                background: #F0F2F8;

                p.label {
                  font-size: 12px;
                  line-height: 100%;
                  text-align: center;
                  color: #787C8D;
                }

                p.value {
                  font-size: 18px;
                  line-height: 100%;
                  text-align: center;
                  color: #202539;
                  font-family: SemiBold;
                }
              }
            }

            .progress__circle__content-image {
              top: 35px;
              left: auto;
            }
          }
        }
      }
    }
  }
}
</style>

<style lang="scss">
.info__styled__text {
  strong {
    color: #2F57E9;
    font-family: Medium;
  }
}

nav.promoution__swiper__nav {
  .start {
    width: 100%;
  }

  span.swiper-pagination-bullet {
    height: 10px;
    width: 10px;
    min-width: 10px;
    margin: 0 8px 0 0;
    background: #CAD2EB;
    border-radius: 10px;
    opacity: 1;
    transition: .2s;

    &.swiper-pagination-bullet-active {
      width: 20px;
      min-width: 20px;
      background: #2F57E9;
    }
  }
}
</style>
